import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule
} from '@angular/material';
import { StoreModule } from './store/store.module';
import { ServiceModule } from './service/service.module';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatCardModule,
        MatDividerModule,
        MatIconModule,
        MatRadioModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        ServiceModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatCardModule,
        MatDividerModule,
        MatIconModule,
        MatRadioModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        ServiceModule
    ]
})
export class SharedModule {}
