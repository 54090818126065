import { NgModule } from '@angular/core';
import * as ApiServiceProxy from './service.proxies';

@NgModule({
    providers: [
        ApiServiceProxy.CommonLookupService
    ]
})
export class ServiceModule {
}
