import { Inject, Injectable, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

// import 'rxjs/add/observable/fromPromise';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/mergeMap';
// import 'rxjs/add/operator/catch';

import { SelectableString } from '../model/selectable';

import * as moment from 'moment';

export const API_BASE_URL = new InjectionToken('API_BASE_URL');

@Injectable()
export class CommonLookupService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : '';
    }

    getCountries(): Observable<SelectableString[]> {
        let url = this.baseUrl + '/api/v1/common/countries';
        url = url.replace(/[?&]$/, '');

        return this.http.get<SelectableString[]>(url);
    }

    getStates(): Observable<SelectableString[]> {
        let url = this.baseUrl + '/api/v1/common/states';
        url = url.replace(/[?&]$/, '');

        return this.http.get<SelectableString[]>(url);
    }
}
