import { Component, Injector, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SelectableString } from '../shared/model/selectable';
import { CommonLookupService } from '../shared/service/service.proxies';

import { Observable } from 'rxjs';
import { fadeInOutTransition } from '../shared/animations';

export class CountryErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const intialState: any = {
  gender: 'M',
  country: '',
  firstName: '',
  lastName: '',
  password: '',
  username: ''
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [
    fadeInOutTransition()
  ]
})
export class AppComponent implements OnInit {
  title = 'example2';
  busy: boolean;
  countries$: Observable<SelectableString[]>;
  profileForm: FormGroup;
  matcher: ErrorStateMatcher = new CountryErrorStateMatcher();

  get username() { return this.profileForm.get('username'); }
  get password() { return this.profileForm.get('password'); }
  get firstname() { return this.profileForm.get('firstName'); }
  get lastname() { return this.profileForm.get('lastName'); }
  get country() { return this.profileForm.get('country'); }
  get gender() { return this.profileForm.get('gender'); }

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _commonLookup: CommonLookupService
  ) {
  }

  ngOnInit(): void {
    this.countries$ = this._commonLookup.getCountries();
    this.profileForm = this._fb.group({
      country: new FormControl('', [Validators.required]),
      username: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(5)]
      ),
      password: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      gender: new FormControl('M', [Validators.required])
    });
  }

  formSubmit(event: any): void {
    console.log('formSubmit: ' + JSON.stringify(event));
    this.busy = true;
    setTimeout(() => {
      this.busy = false;
      const dialogRef = this.dialog.open(SuccessDialogComponent, {width: '500px', height: '200px'});
      dialogRef.afterClosed()
        .subscribe(result => console.log('Dialog was closed'));
    }, 3000);
  }

  clear(): void {
    // Reset to initial state
    this.profileForm.reset(intialState);
  }

  countryChanged(event: any): void {
    console.log('countryChanged: ' + JSON.stringify(event));
  }
}

@Component({
  selector: 'app-success-dialog',
  template: `
  <h1 mat-dialog-title>Save Success</h1>
  <div mat-dialog-content>
    <p>You have successufully saved your profile.</p>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [color]="'primary'" (click)="close()" cdkFocusInitial>Ok</button>
  </div>
  `
})
export class SuccessDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SuccessDialogComponent>
  ) {}

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }
}
