import { environment } from './environments/environment';
import { AppConsts } from './shared/AppConsts';

export class AppPreBootstrap {
    static run(callback: () => void): void {
        AppPreBootstrap.getApplicationConfig(() => {
            // Do nothing atm..
            callback();
        });
    }

    private static async getApplicationConfig(callback: () => void) {
        const appconfig = environment.production
            ? '/assets/appconfig.production.json'
            : '/assets/appconfig.development.json';

        // Do ajax call to get JSON
        const ajax  = new Promise<any>((resolve, reject) => {
            const xmlHttpRequest = new XMLHttpRequest();
            xmlHttpRequest.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    const config = JSON.parse(this.responseText);
                    resolve(config);
                }
            };
            xmlHttpRequest.onerror = function() {
                if (this.status === 500 || this.status === 404) {
                    reject('Http error');
                }
            };
            xmlHttpRequest.open('GET', appconfig, true);
            xmlHttpRequest.send();
        });

        try {
            const config: any = await ajax;

            AppConsts.remoteServiceBaseUrl = config.remoteServiceBaseUrl;
            AppConsts.appBaseUrl = config.appBaseUrl;
        } catch (e) {
        }

        callback();
    }
}
