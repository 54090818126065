import { Component, HostBinding } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

export function fadeInOutTransition(): any {
    // Define animation
    return trigger('fadeInOut', [
        state('hide', style({
            visibility: 'hidden',
            opacity: '0'
        })),
        state('show', style({
            visibility: 'visible',
            opacity: '1'
        })),
        transition('show => hide', [
            // style({transform: 'translateX(0)'}),
            animate('1.25s')
        ]),
        transition('hide => show', [
            // style({transform: 'translateX(100%)'}),
            animate('1.25s')
        ])
    ]);
}
